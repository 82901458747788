/* Join with phone number */

/*
Dependencies:

<script src="https://cdnjs.cloudflare.com/ajax/libs/libphonenumber-js/0.2.31/libphonenumber-js.min.js"></script>
*/

var Masker = require('maskerjs');
var libphonenumber = require('libphonenumber-js');

function hideEl(el) {
    removeClass(el, 'open');

    setTimeout(function () {
        addClass(el, 'close');
    }, 200);
}

function showEl(el) {
    removeClass(el, 'close');

    setTimeout(function () {
        addClass(el, 'open');
    }, 0);
}

function hasClass(el, name) {
    var classList = el.className.split(/\s+/);

    return classList.indexOf(name) !== -1;
}

function addClass(el, className) {
    var classList = el.className.split(/\s+/);
    var classNames = className.split(/\s+/);

    for (var i = 0; i < classNames.length; i++) {
        if (classList.indexOf(classNames[i]) === -1) {
            classList.push(classNames[i]);
        }
    }

    el.className = classList.join(' ');
}

function removeClass(el, className) {
    var classList = el.className.split(/\s+/);
    var classNames = className.split(/\s+/);

    classList = classList.filter(function (name) {
        return classNames.indexOf(name) === -1;
    });

    el.className = classList.join(' ');
}

var telMasker = new Masker(
    [
        '+_-___-___-____',
        '+__-___-___-____',
        '+___-___-___-____',
        '+___-___-____-____',
        '+___-____-____-____'
    ],
    /^[0-9]$/ // allowed chars
);

window.telMasker = telMasker;

Array.prototype.forEach.call(
    document.querySelectorAll('input[type="tel"]'),
    function maskTel(tel) {
        telMasker.bind(tel);

        tel.addEventListener('keyup', function () {
            var val = '+' + telMasker.unmask(tel.value)
                .text;

            if (libphonenumber.isValidNumber(val)) {
                //   console.log('valid', val);
                enableSendBtn(tel.name);
            } else {
                //   console.log('invalid', val);
                disableSendBtn(tel.name);
            }
        }, false);
    }
);

function enableSendBtn(id) {
    var phoneBtn = document.getElementById(id + '-btn');
    var phoneNum = document.getElementById(id);

    removeClass(phoneBtn, 'disabled');
    if (hasClass(phoneNum, 'error')) {
        addClass(phoneNum, 'info');
        removeClass(phoneNum, 'error');
    }
}

function disableSendBtn(id) {
    var phoneBtn = document.getElementById(id + '-btn');

    addClass(phoneBtn, 'disabled');
}

function open_join_modal() {
    var modal = document.getElementById('join-modal');
    showEl(modal);
}

window.open_join_modal = open_join_modal;

function close_join_modal() {
    var modal = document.getElementById('join-modal');
    hideEl(modal);
}

window.close_join_modal = close_join_modal;

function clean_phone_number(id) {
    var phoneBtn = document.getElementById(id + '-btn');
    var phoneInput = document.getElementById(id + '-input');
    var phoneNumber = document.getElementById(id);

    phoneBtn.className = 'btn big submit disabled';
    phoneNumber.className = 'join-with-phone';
    phoneInput.value = '';
}

window.clean_phone_number = clean_phone_number;

function send_a_download_link(id) {
    var phoneInput = document.getElementById(id + '-input');
    var phoneBtn = document.getElementById(id + '-btn');
    var phoneNumber = document.getElementById(id);

    if (!phoneInput.value && hasClass(phoneBtn, 'disabled')) {
        addClass(phoneNumber, 'info');

        return;
    }

    var phone = '+' + phoneInput.value.replace(/[^0-9]/, '');

    if (libphonenumber.isValidNumber(phone)) {
        // console.log('we are sending a link to:', phone);

        addClass(phoneNumber, 'waiting');

        request('https://sidestory.com/sendDownloadLink/', {
            method: 'POST',
            body: JSON.stringify({
                device: platform.description,
                phone: phone
            })
        }, function (status, res) {
            if (status !== 200) {
                addClass(phoneNumber, 'error');
            } else {
                clean_phone_number(id);

                addClass(phoneNumber, 'success');
            }
        });
    } else {
        addClass(phoneNumber, 'error');
    }
}

window.send_a_download_link = send_a_download_link;

function request(url, options, cb) {

    method = options.method || 'GET';

    var xhr = new XMLHttpRequest();

    if (!xhr) {
        return false;
    }

    xhr.onreadystatechange = function () {
        if (xhr.readyState === XMLHttpRequest.DONE) {
            cb(xhr.status, xhr.responseText);
        }
    };

    xhr.open(method, url);
    xhr.send(options.body);
}
