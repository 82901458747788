$(document)
    .ready(function () {
        var navopen = false;
        // mobile menu click function
        $(".open-nav")
            .on('click', function () {
                if (navopen) {
                    $(".move-for-side-nav")
                        .removeClass('nav-open');

                    setTimeout(function () {
                        $(".side-nav")
                            .removeClass('nav-open');
                    }, 200);
                } else {
                    $(".move-for-side-nav")
                        .addClass('nav-open');

                    $(".side-nav")
                        .addClass('nav-open');
                }
                navopen = !navopen;

                return false;
            });
        $(document)
            .on('click', function (e) {
                // console.log(navopen);
                //if the click has happend inside the mobile-nav-menu or mobile-nav-toggle then ignore it

                $(".move-for-side-nav")
                    .removeClass('nav-open');

                setTimeout(function () {
                    $(".side-nav")
                        .removeClass('nav-open');
                }, 200);

                navopen = false;
            });

        // color change
        $(document)
            .scroll(function () {
                scroll_start = $(this)
                    .scrollTop();
                if (scroll_start > 60) {
                    $('.title-nav.fixed')
                        .addClass('dark');
                } else {
                    $('.title-nav.fixed')
                        .removeClass('dark');
                }
            });
    });
