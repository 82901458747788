var joinWaitlist = function () {
    try {
        failure('');

        var phone = telMasker.unmask(document.getElementById('phone')
                .value)
            .text;

        var input = {
            device: platform.description,
            phone: phone
        };

        console.log('input', input);

        if (!phone) {
            invalidPhone();
            return false;
        }

        AWS.config.region = 'us-east-1';
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: 'us-east-1:a4f06d55-500a-41e6-8076-d7cb209c243d'
        });
        var lambda = new AWS.Lambda();

        // call lambda
        lambda.invoke({
            FunctionName: 'joinWaitlist',
            Payload: JSON.stringify(input)
        }, function (err, data) {
            if (err) {
                console.log(err, err.stack);
                failure('Error joining waitlist.');
            } else {
                if (data.StatusCode === 200) {
                    var output = JSON.parse(data.Payload);

                    if (data.FunctionError) {
                        //if (0) {
                        console.log('error', data);
                        invalidPhone();
                    } else {
                        console.log('output', output);
                        success();
                    }
                }
            }
        });
    } catch (e) {
        console.log('error adding to waitlist', e);
        return false
    }
}

var invalidPhone = function () {
    failure("Please enter a valid phone number.");
}

var failure = function (msg) {
    var e = document.getElementById('join-waitlist-error');
    if (msg && msg != "") {
        e.innerText = msg;
        e.className = '';
    } else {
        e.className = 'hidden';
    }
}

var success = function () {
    var j = document.getElementById('join-waitlist');
    j.className = '';
}

var waitlist = {};
waitlist.join = joinWaitlist;

window.waitlist = waitlist;
